.modal {
  position: relative;
  width: 347px;
  background-color: white;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  &-wrapper {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0, 0.3);
    z-index: 1000;
  }
  &-close {
    position: absolute !important;
    top: 0;
    right: 0;
    z-index: 1001;
  }
  &-body {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 20px 10px;
    box-sizing: border-box;
  }
}

@media (min-width: 700px) {
  .modal {
    width: 422px;
  }
}
