a {
  margin-top: 30px;
}

.form {
  &-field {
    margin-bottom: 10px;
    min-height: 75px;

    &.agree {
      min-height: 30px;
      margin-bottom: 10px;
    }
  }

  &-subtext {
    font-family: TTNormRegular, sans-serif;
    width: 325px;
  }

  &-input {
    margin-bottom: 0;

    &-error {
      margin-bottom: 0;
      border: 1px solid red;

      &:focus {
        border: 1px solid red;
      }
    }

  }
}

.error-message {
  display: block;
  margin-top: 4px;
  color: red;
  font-family: TTNormRegular, sans-serif;
  font-size: 14px;
  width: 325px;
}

.info-message {
  margin-left: 5px;
  display: block;
  margin-top: 4px;
  color: #bebebe;
  font-family: TTNormRegular, sans-serif;
  font-size: 12px;
  width: 325px;
}

form {
  -webkit-appearance: none;
}

input {
  display: block;
  font-family: TTNormRegular, sans-serif;
  font-size: 18px;
  padding: 0 10px;
  box-shadow: 0 4px 7px rgb(0 0 0 / 25%);
  -webkit-appearance: none;
  border-radius: 10px;
  border: 1px solid transparent;
  width: 325px;
  height: 48px;
  margin-bottom: 30px;

  &:focus {
    outline: none;
    border: 1px solid #73c120;
  }

}

#agree-checkbox {
  display: none;

  &:checked ~ .label-checkbox::before {
    background-color:  #73c120;
  }
}

.label-checkbox {
  font-family: TTNormRegular, sans-serif;
  position: relative;
  margin-left: 35px;
  &::before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    background-color:  transparent;
    border: 1px solid #73c120;
    border-radius: 5px;
    left: -30px;
    top: 50%;
    transform: translateY(-50%);
  }
  &:hover::before {
    cursor: pointer;
  }
}

@media (min-width: 700px) {
  input {
    width: 400px;
  }

  .form-subtext {
    width: 400px;
  }

  .error-message {
    width: 400px;
  }
}

.wrap {
  height: 800px;
  max-height: 800px;
  width: 100%;
}

.container, .wrap {
  flex-direction: column;
  display: flex;
  align-items: center;
}

.container {
  height: 100vh;
}

.logo {
  width: 110px;
  height: 160px;
  margin-bottom: 33px;
  margin-top: 120px;
}

img {
  border-style: none;
}

h1 {
  font-family: TTNormBold, sans-serif;
  font-weight: 700;
  font-size: 26px;
  margin: 0 0 33px;
  text-align: center;
}

.green-button {
  font-family: TTNormRegular, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  background: #73c120;
  width: 347px;
  height: 48px;
  border: none;
  box-shadow: 0 4px 7px rgb(0 0 0 / 25%);
  border-radius: 10px;
  color: #fff;
  text-decoration: none;
  margin-bottom: -15px;

  &:hover {
    opacity: .8;
    cursor: pointer;
  }

  &.disable {
    background-color: #969696;
    &:hover {
      opacity: 1;
      cursor: default;
    }
  }
}

.link-another-page {
  display: block;
  position: relative;
  font-family: TTNormRegular, sans-serif;
  font-size: 16px;
  text-decoration: none;
  color: #5f5ff8;
  margin: 30px 0;
  text-align: center;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

@media (min-width: 700px) {
  .green-button {
    width: 420px;
  }
}

.success-animation {
  margin: 20px auto;
}

.checkmark {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #4bb71b;
  stroke-miterlimit: 10;
  box-shadow: inset 0 0 0 #4bb71b;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  position:relative;
  top: 5px;
  right: 5px;
  margin: 0 auto;
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #4bb71b;
  fill: #fff;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;

}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.notification-message {
  width: 347px;
  font-size: 18px;
  word-break: break-word;
  margin-bottom: 10px;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%, 100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0 0 0 30px #4bb71b;
  }
}